import React from 'react'
// import { GoogleLogin } from '@react-oauth/google'
// import _logDebug from '@common/util/log'
// import { trpcProxy } from './trpc'

export default function HomePage() {
  return (
    <>
      <h1>Feature Web, coming soon...</h1>

      {/* <GoogleLogin
        onSuccess={credentialResponse => {
          ;(async () => {
            _logDebug(credentialResponse)
            _logDebug(credentialResponse.credential)
            if (credentialResponse.clientId && credentialResponse.credential) {
              const input = {
                clientId: credentialResponse.clientId,
                credential: credentialResponse.credential,
              }
              const verified = await trpcProxy.user.verifyGoogleUser.mutate(input)
              _logDebug('user login: ', verified)
              if (verified) {
                window.location.href = '/app'
              }
            }
          })()
        }}
        onError={() => {
          _logDebug('Login Failed')
        }}
      /> */}
    </>
  )
}

// Object
// eyJhbGciOiJSUzI1NiIsImtpZCI6IjQ4YTYzYmM0NzY3Zjg1NTBhNTMyZGM2MzBjZjdlYjQ5ZmYzOTdlN2MiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL2FjY291bnRzLmdvb

// eyJhbGciOiJSUzI1NiIsImtpZCI6IjQ4YTYzYmM0NzY3Zjg1NTBhNTMyZGM2MzBjZjdlYjQ5ZmYzOTdlN2MiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL2FjY291bnRzLmdvb2dsZS5jb20iLCJhenAiOiI3NTU4MzAxMTE3NTEtOXI2MjBncGkwZjBobzdkY
